import { Directive, ElementRef, Input, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { Subject, takeUntil } from 'rxjs';

export enum BtnclickStatus {
  Pending,
  Success,
  Failed,
}

@Directive({
  selector: '[pbbBtnWithSpinner]',
  standalone: true,
})
export class BtnWithSpinnerDirective extends UntilDestroy implements OnInit {
  spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
  @Input() pbbBtnWithSpinner!: Subject<BtnclickStatus>;
  constructor(private viewContainerRef: ViewContainerRef, private renderer: Renderer2, private elementRef: ElementRef) {
    super();
    this.addIcon();
  }
  ngOnInit(): void {
    this.pbbBtnWithSpinner.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === BtnclickStatus.Pending) {
        this.spinner.location.nativeElement.style.display = 'block';
      } else {
        this.spinner.location.nativeElement.style.display = 'none';
      }
    });
  }

  addIcon() {
    this.spinner.instance.mode = 'indeterminate';
    this.spinner.instance.color = 'accent';
    this.spinner.instance.diameter = 0;
    this.spinner.instance.diameter = 20;
    this.renderer.appendChild(this.elementRef.nativeElement, this.spinner.location.nativeElement);
    this.renderer.addClass(this.elementRef.nativeElement, 'btn-with-spinner');
    this.spinner.location.nativeElement.style.display = 'none';
  }
}
